import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IWizardManager, IWizardOptions } from '@/interfaces';
import { ServerDataModule } from '@/store/serverData';

@Component
export default class WizardComponent extends Vue implements IWizardManager {
    options = {
    };

    viewStack = [];
    content: any = null;
    currentWidth = 600; // vue-js-modal doesn't auto resize width

    @Prop({ default: null })
    closed: Function;

    get width() { return this.currentWidth; }

    @Prop({ default: <any>'auto' })
    height: string;

    @Prop({ default: null })
    parent: any;

    @Prop({ default: null })
    video: any;

    get serverData() {
        return ServerDataModule.serverData;
    }

    @Watch('serverData.expired', { immediate: true })
    onExpiredChanged(val: string, oldVal: string) {
        this.close();
    }

    @Watch('serverData.notAuthorized', { immediate: true })
    onNotAuthorizedChanged(val: string, oldVal: string) {
        this.close();
    }

    created() {
        Debug.setDebugModule('Wizard', this);
    }

    mounted() {
    }

    navigateTo(name: string, width?: number) {
        this.navigate({
            name: name,
            width: width
        });
    }

    navigate(model: IWizardOptions) {
        Debug.log('navigateTo', model.name);

        let width = model.width || 600;
        let component = this.mapToComponent(model.name);

        if (!component) {
            Debug.error("Unknown component " + name);
            this.close();
            return;
        }

        let modal = (<any>this.$modal);

        if (this.content) {
            if (model.clearStack) {
                this.viewStack.splice(0, this.viewStack.length);
            } else {
                this.viewStack.push({
                    component: this.content,
                    width: this.currentWidth,
                });
            }
        }

        this.content = component;
        this.currentWidth = width;

        modal.hide('wizard');
        setTimeout(() => {
            modal.show('wizard');
        }, 100);
    }

    pop() {
        if (this.viewStack.length > 0) {
            this.viewStack.pop();
        }
    }

    back() {
        if (!this.viewStack.length) {
            this.close();
            return;
        }

        let modal = (<any>this.$modal);
        modal.hide('wizard');

        let view = this.viewStack.pop();
        this.content = view.component;
        this.currentWidth = view.width;

        setTimeout(() => {
            modal.show('wizard');
        }, 250);
    }

    show() {
        (<any>this.$modal).show('wizard');
    }

    close() {
        (<any>this.$modal).hide('wizard');

        if (this.closed)
            this.closed(false);

        this.content = null;
    }

    private mapToComponent(component: string) {
        switch (component) {
            case 'addNewStudio':
                return () => import('@/wizards/addNewStudio/addNewStudio.vue');

            case 'categories':
                return () => import('@/wizards/categories/categories.vue');

            case 'changeCreationDate':
                return () => import('@/wizards/changeCreationDate/changeCreationDate.vue');

            case 'deleteVideo':
                return () => import('@/wizards/deleteVideo/deleteVideo.vue');

            case 'editBackground':
                return () => import('@/wizards/editBackground/editBackground.vue');

            case 'editLogo':
                return () => import('@/wizards/editLogo/editLogo.vue');

            case 'editStudio':
                return () => import('@/wizards/editStudio/editStudio.vue');

            case 'permissions':
                return () => import('@/wizards/permissions/permissions.vue');

            case 'renameAsset':
                return () => import('@/wizards/renameAsset/renameAsset.vue');

            case 'settings':
                return () => import('@/wizards/settings/settings.vue');

            case 'studioSettings':
                return () => import('@/wizards/studioSettings/studioSettings.vue');

            case 'turnOffPortal':
                return () => import('@/wizards/turnOffPortal/turnOffPortal.vue');

            case 'uploadVideos':
                return () => import('@/wizards/uploadVideos/uploadVideos.vue');

            case 'videoAdmin':
                return () => import('@/wizards/videoAdmin/videoAdmin.vue');

            case 'deleteVideo':
                return () => import('@/wizards/deleteVideo/deleteVideo.vue');

            default:
                return () => import('@/wizards/settings/settings.vue');
        }
    }

}
